import { FetchDynamicField } from "../../services/workspace/taskManager";
import { setAllDynamicFields } from "./action";
import dynamicFieldReducer from "./reducer";

const getAllDynamicFields = () => {
  return async (dispatch) => {
    try {
      const res = await FetchDynamicField();
      if (res.status === 200) {
        console.log("done", res.status);
        dispatch(setAllDynamicFields(res?.data || []));
      }
    } catch (error) {
      console.log("Error fetching dynamic fields:", error);
    }
  };
};

export { dynamicFieldReducer, getAllDynamicFields, setAllDynamicFields };
