import { SET_DYNAMIC_ALL_FIELDS } from "./types";

const dynamicFieldReducer = (state = [], action) => {
  switch (action.type) {
    case SET_DYNAMIC_ALL_FIELDS:
      return action.payload || [];

    default:
      return state;
  }
};

export default dynamicFieldReducer;
